import dynamic from "next/dynamic";
import { useCallback, useEffect, useState } from "react";

import HomeFooter from "@/components/home/HomeFooter";
import { MOBILE_SCREEN } from "@/constants";
import useIsMobile from "@/hooks/useIsMobileDevice";

const Header = dynamic(
  import("@/components/newFoliko/header").then((mod) => mod.Header)
);
interface Props {
  children: JSX.Element | JSX.Element[];
}

export const HomeLayoutV2 = ({ children }: Props) => {
  const { isMobile } = useIsMobile(MOBILE_SCREEN);
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = useCallback(
    ({ top }: { top: number }) => {
      if (!scrolling && top > 0) {
        setScrolling(true);
      } else if (scrolling && top === 0) {
        setScrolling(false);
      }
    },
    [scrolling]
  );

  const onScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    handleScroll({ top: scrollTop });
  }, [handleScroll]);

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("scroll", onScroll);

      return () => {
        window.removeEventListener("scroll", onScroll);
      };
    }
  }, [isMobile, onScroll]);

  return (
    <div className="fixed inset-0 font-roboto bg-white flex flex-col items-center overflow-y-auto">
      <div className="w-full h-fit relative">
        <Header scrolling={scrolling} />
        <div className="md:container lg:w-11/12 md:my-8 my-6 md:mx-4 mx-2 lg:mx-auto px-0 flex justify-between md:flex-row flex-col">
          {children}
        </div>
        <HomeFooter />
      </div>
    </div>
  );
};
