import Link from "next/link";
import React from "react";

import { ArticleImage } from "@/components/ui/ArticleImage";
import { NarrativeSummary } from "@/model/api";
import { range } from "@/util";
import { getArticleUrl, getTickerUrl } from "@/util/article";

import ArticleTeaser from "./ArticleTeaser";

interface Props {
  list: NarrativeSummary[];
  loading: boolean;
}

const LoadingPlaceholder = () => (
  <div className="animate-pulse flex flex-wrap relative w-full justify-between">
    {range(0, 4).map((item, index) => (
      <div
        key={item}
        className={`relative md:space-y-4 space-y-2 pb-4 ${
          item === 0
            ? "w-full"
            : "md:w-[50%] w-full md:my-0 my-4 flex md:flex-col flex-row items-start"
        } ${
          item === 0 ? "p-0" : item % 2 === 0 ? "md:pl-2 pl-0" : "md:pr-2 pr-0"
        } border-b border-t-dolphin-silver border-b-1 last:border-0`}
      >
        <div className="space-y-2 order-1 md:order-none flex-1">
          <div className="flex relative w-full items-center space-x-2">
            <div className="w-12 h-3 rounded-full bg-gray-200"></div>
            <div>-</div>
            <div className="w-12 h-3 rounded-full bg-gray-200"></div>
          </div>
          <div className="w-full space-y-2">
            <div className="w-full h-4 rounded-full bg-gray-200"></div>
            <div className="w-1/2 h-4 rounded-full bg-gray-200"></div>
          </div>

          {index === 0 && (
            <div className="w-full space-y-2">
              <div className="w-full h-3 rounded-full bg-gray-200"></div>
              <div className="w-full h-3 rounded-full bg-gray-200"></div>
              <div className="w-1/2 h-3 rounded-full bg-gray-200"></div>
            </div>
          )}
        </div>

        <div
          className={`rounded-lg bg-gray-200 ${
            item === 0
              ? "w-full md:h-96 h-48"
              : "md:h-48 h-20 md:w-full w-20 mr-2 md:mr-0"
          }`}
        ></div>
      </div>
    ))}
  </div>
);
const MostImpactful: React.FC<Props> = React.memo(({ list, loading }) => {
  if (loading) {
    return <LoadingPlaceholder />;
  }

  return (
    <div className="flex flex-wrap relative w-full justify-between">
      {list.map((narrative, index) => {
        const articleLink = getArticleUrl(narrative);
        return (
          <div
            key={index}
            className={`group bg-white relative md:pb-0 pb-4 ${
              index !== 0 && "mt-4"
            } ${
              index === 0
                ? "p-0"
                : index % 2 === 0
                ? "md:pl-4 pl-0"
                : "md:pr-4 pr-0"
            } ${
              index === 0
                ? "w-full"
                : "w-full md:w-1/2 flex md:flex-col flex-row items-start justify-between"
            } ${
              index !== list.length - 1 && index !== list.length - 2
                ? "border-b border-t-dolphin-silver border-b-1"
                : "md:border-0 border-b border-t-dolphin-silver border-b-1 last:border-0"
            }`}
          >
            <div
              className={`order-1 md:-order-none flex-1 ${
                index !== 0 && "ml-4"
              } md:ml-0`}
            >
              <Link href={`${getTickerUrl(narrative.Ticker)}`} passHref>
                <a
                  tabIndex={0}
                  className="flex group items-center space-x-2 ease-in-out outline-none focus:outline-none rounded-md focus:z-10 focus:ring-1 focus:ring-blue-light/100 cursor-pointer"
                >
                  <h4 className="text-fi-red text-xs font-bold transition-all duration-150 group-hover:text-blue-light group-hover:drop-shadow-sm">
                    {narrative.Ticker}
                  </h4>
                  <div className="">-</div>
                  <p className="text-gray-500 text-xs transition-all duration-150 group-hover:font-bold group-hover:text-fi-black group-hover:drop-shadow-sm">
                    {narrative.CompanyName}
                  </p>
                </a>
              </Link>
              <Link href={articleLink} passHref>
                <a
                  className={`${
                    index !== 0 &&
                    "flex flex-col justify-between h-auto cursor-pointer"
                  }`}
                >
                  <p
                    className={`${
                      index === 0
                        ? "text-2xl md:text-4xl"
                        : "text-xs md:text-lg article-line-height"
                    } font-bold text-fi-black md:hover:text-fi-red`}
                  >
                    {narrative.Headline}
                  </p>
                  {index === 0 ? (
                    <ArticleTeaser
                      ticker={narrative.Ticker}
                      narrativeInfo={narrative}
                      truncate={index !== 0}
                    />
                  ) : (
                    <></>
                  )}
                </a>
              </Link>
            </div>

            <div
              className={`bg-gradient-to-b from-zinc-400 via-zinc-500 to-black w-full relative rounded-md md:rounded md:flex-grow flex-grow-0 my-0 md:my-4 ${
                index === 0
                  ? "md:max-h-96 md:h-96 md:min-h-96 max-h-48 min-h-48 h-48 w-full"
                  : "md:max-h-48 md:min-h-48 md:h-48 max-h-20 min-h-20 h-20 md:w-full w-20"
              }`}
            >
              <Link href={articleLink} passHref>
                <a
                  className="absolute w-full h-full sm:px-0 mix-blend-overlay"
                  id={narrative.Id}
                >
                  <ArticleImage
                    ticker={narrative.Ticker}
                    narrative={narrative}
                  />
                </a>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
});

MostImpactful.displayName = "MostImpactful";

export default MostImpactful;
