/* eslint-disable react/display-name */
import Link from "next/link";
import { memo } from "react";

import { ArticleImage } from "@/components/ui/ArticleImage";
import { BarTitle } from "@/components/ui/folikoInsights/BarTitle";
import { NarrativeSummary, TopNarratives } from "@/model/api";
import { range } from "@/util";
import { getArticleUrl, getTickerUrl } from "@/util/article";

interface Props {
  list: NarrativeSummary[];
  listParam: keyof TopNarratives;
  loading: boolean;
}

const InsightsListLoading = () => {
  return (
    <ul className="animate-pulse mt-4 space-y-2">
      {range(1, 5).map((id, index) => (
        <li
          key={index}
          className="scale-100 transition-all duration-150 border-gray-200 bg-white sm:border-b-2 rounded-none relative pb-3 w-full"
        >
          <div className="w-full flex space-x-2 sm:space-x-4 justify-between items-start">
            <div className="relative w-[97px] h-16 sm:w-28 sm:h-[70px] rounded-none bg-gray-200"></div>
            <div className="flex flex-col h-full w-full space-y-2">
              <div className="h-4 bg-gray-200 rounded-full w-24"></div>
              <div className="h-4 bg-gray-200 rounded-full w-full"></div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export const InsightsList: React.FC<Props> = memo(
  ({ list, listParam, loading }) => {
    if (loading) {
      return <InsightsListLoading />;
    }

    return (
      <div className="my-4 sm:mb-8 sm:mt-0">
        <div className="mr-6 mb-6">
          <BarTitle
            title={listParam === "TopWeekly" ? "Week In Review" : "Most Read"}
            className="text-xl sm:text-lg"
          />
        </div>
        <ul className="flex flex-col w-full justify-between space-y-4 ">
          {list.map((narrative: NarrativeSummary, index: number) => {
            const articleLink = getArticleUrl(narrative);

            return (
              <li
                key={`${narrative.Id}-${narrative.Date}-${index}`}
                className="scale-100 transition-all duration-150 border-t-dolphin-silver border-b last:border-b-0 bg-white rounded-none relative pb-3 w-full"
              >
                <div className="w-full flex space-x-2 sm:space-x-4 justify-between items-start">
                  <Link href={articleLink} passHref>
                    <a className="relative w-[101px] h-20 sm:w-28 sm:h-[70px] rounded-none">
                      <ArticleImage
                        ticker={narrative.Ticker}
                        narrative={narrative}
                      />
                    </a>
                  </Link>
                  <div className="flex flex-col h-full w-full pl-2 sm:pl-0">
                    <Link href={getTickerUrl(narrative.Ticker)} passHref>
                      <a className="text-fi-red text-xs sm:text-sm font-bold cursor-pointer hover:text-blue-light pb-1 sm:pb-0">
                        {narrative.Ticker}
                      </a>
                    </Link>
                    <Link href={articleLink} passHref>
                      <a className="text-xs sm:text-sm font-semibold leading-normal sm:leading-tight text-fi-black cursor-pointer hover:text-fi-red">
                        {narrative.Headline}
                      </a>
                    </Link>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
);
