import dayjs from "dayjs";
import type { NextRouter } from "next/router";

import { formatTextToSlug } from "@/components/snapshot/helpers";
import { NarrativeSummary } from "@/model/api";
import { RandomString } from "@/util";
import { ApiBaseUrl, BaseUrl } from "@/util/app";

export const getArticleShareUrl = (router: NextRouter) => {
  const url = new URL(`${BaseUrl}${router.asPath}`);
  const query = new URLSearchParams(url.search);
  query.set("cid", RandomString(16));
  url.search = query.toString();
  return url.toString();
};

export const getArticleImageUrl = (narrative: NarrativeSummary) =>
  `${ApiBaseUrl}/edge_public/article_img/${getArticleBaseUrl(narrative)}${
    narrative.Enrichment?.LastUpdated
      ? `?id=${narrative.Enrichment.LastUpdated}`
      : ""
  }`;

export const getTickerUrl = (ticker: string) => `/lite/${ticker}`;

export const getArticleUrl = (narrative: NarrativeSummary) =>
  `/article/${getArticleBaseUrl(narrative)}`;

export const getArticleBaseUrl = (narrative: NarrativeSummary) =>
  `${narrative.Ticker}/${dayjs(narrative.Date).format("YYYY/MM/DD")}/${
    narrative.Slug ?? formatTextToSlug(narrative.Headline)
  }`;
