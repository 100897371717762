import parse from "html-react-parser";
import { NextRouter } from "next/router";

export const isHome = (router: NextRouter) => router.pathname === "/";

export const WindowLocationAssign = (url: string) =>
  typeof window !== "undefined" && window.location.assign(url);

export const range = (start: number, stop: number) =>
  Array.from({ length: stop - start + 1 }, (_, i) => start + i);

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) {
    newWindow.opener = null;
  }
};

export const sleep = (ms: number): Promise<never> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const ObjectValues = <T extends {}>(obj: T) => {
  return Object.values(obj) as typeof obj[keyof T][];
};

export const StringToBool = (str: string): boolean | undefined => {
  return str.toLowerCase() == "true"
    ? true
    : str.toLowerCase() == "false"
    ? false
    : undefined;
};

export const RandomString = (n: number) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < n; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const removeStringSpace = (s: string) => s.replace(" ", "");

export const replaceStringSpaceHyphen = (s: string) => s.replace(" ", "-");

export const htmlDecode = (input: string) => parse(input);

export const htmlKeyPairMatch = (html: string) => {
  const codeRegex = /{{\s*\w+\s*:\s*\w+\s*}}/g;
  return html ? html.match(codeRegex) : null;
};

export const mergeTwoObjectArray = (array1: any, array2: any) => {
  array2 = array2 || [];
  array1 = array1 || [];
  return Object.values(
    [...array1, ...array2].reduce((result, obj) => {
      if (obj.id) {
        result = {
          ...result,
          [obj.id]: { ...obj },
        };
      }

      if (obj.Id) {
        result = {
          ...result,
          [obj.Id]: { ...obj },
        };
      }
      return result;
    }, {})
  );
};

export const ellipsesText = (input: string): string => {
  const MIN_LENGTH = 12;

  if (input.length <= MIN_LENGTH) {
    return input;
  }

  return input.slice(0, MIN_LENGTH - 3) + "...";
};

export const truncateHeadline = (
  headline: string,
  wordLimit: number = 30
): string => {
  const words = headline.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return headline;
};
